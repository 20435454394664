import './index.css';
import ResponsiveAppBar from '../Navbar';

function Header(){

    return (
        <ResponsiveAppBar/>
    )
}

export default Header;