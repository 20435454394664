import './index.css';

function Footer(){
    return(
        <footer>
            <h2><a target='_blank' href='http://www.sunsalesystem.com.br/' >SunSale System</a></h2>
        </footer>
    )
}

export default Footer;